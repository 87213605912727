import { HitModes, Tabs } from '@/helpers'

export const cardOrigin = (isFromMap: boolean, tabs: string | undefined, context: string): string => {
  const isHitlist = context === HitModes.HIT_LIST
  const isCarousel = context === HitModes.CAROUSEL
  const isPageAll = tabs === Tabs.ALL
  const isPageTabs = !!tabs && !isPageAll && window.location.href.includes('e/recherche')

  if (isFromMap) {
    return 'Map'
  }
  if (isPageTabs) {
    return 'Search list'
  }
  if (isHitlist) {
    return 'Hitlist'
  }
  if (isCarousel) {
    return 'Carrousel'
  }
  return ''
}

export const buildCardInfo = (hit: any, index: number, origin: string): object => {
  const href = hit.url
  const hrefPath = href.split('/')
  const isToOrgaPage = href.includes('/associations/')
  const isToFormPage = hrefPath.length > 5 && isToOrgaPage

  // we don't have these slugs in api point so we need to get them in url
  const orgnizationSlug = isToOrgaPage ? hrefPath[4] : ''
  const formsSlug = isToFormPage ? hrefPath[hrefPath.length - 1] : ''

  const activityType = hit?.activity_type || hit?.activityType
  const minPrice = hit?.min_price || hit?.minPrice
  const region = hit.place_region || hit?.place?.region
  const department = hit.place_department || hit?.place?.department
  const city = hit.place_city || hit?.place?.city
  const zipCode = hit.place_zipcode || hit?.place?.zipCode
  const categoryTags = hit?.category_tags || hit?.tags
  const formType = hit.form_type || hit.formType || ''
  return {
    activity_type: isToFormPage ? activityType : '',
    card_type: isToFormPage ? 'Campagne' : 'Association',
    card_origin: origin,
    card_position: index + 1 || '',
    category_tags: categoryTags ?? [],
    city: city ?? '',
    form_slug: isToFormPage ? formsSlug : '',
    form_type: isToFormPage ? formType : '',
    ha_tags: hit?.ha_tags ?? [],
    href,
    min_price: minPrice ?? '',
    region: region ?? '',
    department: department ?? '',
    organization_slug: orgnizationSlug,
    postal_code: zipCode ?? ''
  }
}
